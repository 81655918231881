<template>
    <div class="hello">
       
        <div class="screenUse">
          <Nav-bar style="width: 100%;"/>
          <vs-row>
            <vs-col vs-w="12" style="overflow:scroll; height: calc(100vh - 48px);">
              <div style="position: fixed; width: 100%; z-index: 1000;">
                <vs-card class="navbar" style="margin-bottom: -10px; border-bottom: 3px solid #f0c1c5;">
                <div style="width: 7500px; float: left; display: inline-block; margin-bottom: -15px; color: black; font-size: 20px;">
                  <span style="color: #096619; font-weight: bold"><vs-icon icon="home" size="" style="font-size: 18px;"></vs-icon> LISTE DES CLIENTS ET PRODUITS </span>
                  
                </div>
                </vs-card>
              </div>
              
              <div style="padding: 10px 20px; font-family:'Karla', sans-serif; margin-top: 70px;">

                
                <vs-row>

                  <vs-col vs-w="2.3" style="padding-right: 10px;" v-for="item in don_Clients" :key="item.id_client">
                      <el-card class="box-card" style="padding: -15px; height: 270px;  background-color: #ffffff57; border-bottom: 3px solid #f0c1c5;">
                        <vs-icon icon="local_shipping" size="small" bg="#f0c1c5" style="color: #c22d39; font-size: 20px;" round></vs-icon> <span class="titre-zone">{{ item.libelle }}</span> <el-link @click="modifBon(item)" type="info">Modif</el-link>
                            <vs-row class="space-zone">
                              <vs-col vs-w="12" style="display: grid;">
                                <vs-row class="space-zone">
                                  <vs-col vs-w="12" style="display: grid;" v-for="element in don_Produits" :key="element.id_prod">
                                    <span v-if="element.client == item.libelle">{{ element.libelle }} - (<span style="font-weight: bold;">{{ element.nombre_de_donnees }}</span>)<vs-button color="danger" type="flat" v-if="element.nombre_de_donnees == 0" @click="supProd(element.id_prod)">Supr</vs-button></span> 
                                  </vs-col>
                                </vs-row>
                              </vs-col>
                            </vs-row>
                      </el-card>
                    </vs-col>
                    
                  </vs-row>

                  
                <vs-divider/>
                
              </div>

            </vs-col>
          </vs-row>
  
          <vs-popup classContent="popup-example"  title="Nouvelle dépense" :active.sync="popupActivo2">
            <div>
              <vs-row>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Date de la dépense</label>
                  <el-input   type="date" v-model="depensesInfos.date_dep" placeholder="Date de Réception">
                  </el-input>
                </vs-col>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Type de dépense</label>
                  <vs-select
                    style="width: 100%;"
                    v-model="depensesInfos.type_dep"
                    >
                    <vs-select-item :key="index" :value="item.type_dep" :text="item.type_dep" v-for="item,index in donneesTypeDep" />
                  </vs-select>
                </vs-col>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4" v-if="depensesInfos.type_dep == 'VEHICULE'">
                  <label>Camion {{ form.camion }}</label>
                  <vs-select
                    style="width: 100%;"
                    placeholder="Search and select"
                    class="selectExample"
                    label-placeholder="Autocomplete"
                    autocomplete
                    v-model="depensesInfos.matricule"
                    >
                    <vs-select-item :key="index" :value="item.imatriculation" :text="item.imatriculation" v-for="item,index in donneesCamion" />
                  </vs-select>
                  
                </vs-col>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4" v-if="depensesInfos.type_dep == 'CHAUFFEUR'">
                  <label>Chauffeur</label>
                  
                  <vs-select
                    style="width: 100%;"
                    placeholder="Search and select"
                    class="selectExample"
                    label-placeholder="Autocomplete"
                    autocomplete
                    v-model="form.chauffeur"
                    >
                    <vs-select-item :key="index" :value="item.nom_prenom" :text="item.nom_prenom" v-for="item,index in donneesChauf" />
                  </vs-select>
                </vs-col>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4" v-if="depensesInfos.type_dep == 'SALAIRE'">
                  <label>Employé</label>
                  <el-input v-model="depensesInfos.matricule" placeholder="Nom & Prenom">
                  </el-input>
                  
                </vs-col>
                
              </vs-row>
              <vs-row>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Somme</label>
                  <el-input v-model="depensesInfos.somme" placeholder="Somme">
                  </el-input>
                </vs-col>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Motif</label>
                  <el-input v-model="depensesInfos.motif" placeholder="informations">
                  </el-input>
                </vs-col>
              </vs-row>
            </div>
           
            
            <center><vs-button style="margin: 20px; width: 300px;" @click="onSubmit()" color="primary" type="filled">Enregistrer la dépense</vs-button></center>
            <vs-popup title="Inner popup" :active.sync="popupActivo3">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                <br><br>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                <br><br>
                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
              </vs-popup>
          </vs-popup>

          <vs-popup classContent="popup-example"  title="Nouveau type de dépense" :active.sync="popupActivo43">
            <div>
              <el-input v-model="depensesInfos.motif" placeholder="informations">
              </el-input>
            </div>
           
            
            <center><vs-button style="margin: 20px; width: 300px;" @click="onSubmitt()" color="primary" type="filled">Enregistrer Type</vs-button></center>
            <vs-popup title="Inner popup" :active.sync="popupActivo3">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                <br><br>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                <br><br>
                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
              </vs-popup>
          </vs-popup>
  
          <vs-popup classContent="popup-example"  title="Bordereau de livraison" :active.sync="activePrompt9">
            
            <center style="width: 21cm;">
              
              <vue-html2pdf
              :show-layout="false"
              :float-layout="true"
              :enable-download="true"
              :preview-modal="true"
              :paginate-elements-by-height="1100"
              filename="hee hee"
              :pdf-quality="2"
              :manual-pagination="false"
              pdf-format="a4"
              pdf-orientation="landscape"
              pdf-content-width="1000px"

              @progress="onProgress($event)"
              @hasStartedGeneration="hasStartedGeneration()"
              @hasGenerated="hasGenerated($event)"
              ref="html2Pdf"
          >
              <section slot="pdf-content">
                <div>
                  <div style="width: 29.5cm; margin-left: .5cm; margin-top: 20px;" id="html-to-pdf">
                    <div style="width: 100%; padding: 10px">
                      <div style="float: left; display: block;">
                        <img src="../assets/sogecit.png" width="185">
                      </div>
                      <div style="float: left; padding: 12px;">
                        <span style="font-weight: bold; font-size: 19px; font-family: 'Nunito Sans', sans-serif;">RAPPORT VEHICULE - <span><b> </b></span><span style="color: red; font-size: 18px">( total dépenses - <b>0 CFA</b> )</span></span>
                      </div>
                    </div>
                  .
                    <h2></h2>
                    <vs-divider/>
                    <vs-divider/>
                    
                  </div>
                </div>
                  
              </section>
          </vue-html2pdf>
             
                    
            </center>
                  
           
          </vs-popup>

          <vs-prompt
            title="Modification Client"
            color="danger"
            @accept="onUpdate()"
            @close="close"
            :is-valid="validName"
            :active.sync="popupActivo22">
            <div class="con-exemple-prompt">
              <label>Client</label>
                  <el-input style="width: 100%" v-model="clientChoix.libelle" placeholder="Client">
                  </el-input>
            </div>
          </vs-prompt>
        </div>
       
    </div>
  </template>
  
  <script>
  import VueHtml2pdf from 'vue-html2pdf'
  import html2pdf from "html2pdf.js";
  import jsPDF from 'jspdf'
  import html2canvas from 'html2canvas';
  import axios from 'axios';
  import NavBar from '@/components/Navbar.vue'
  export default {
    name: 'HelloWorld',
    computed: {
    users() {
      return this.$store.state.users;
        }
      },
    filters: {
      devise(valeur, devise) {
        const options = {
          style: 'currency',
          currency: devise,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        };
        return valeur.toLocaleString('fr-FR', options);
      },
    },
    components: {
      NavBar,
      VueHtml2pdf
    },
    data() {
      return {
        counter: false,
        prixUnit: 0,
        popupActivo22:false,
        popupActivo43:false,
        dateSearch: [
          new Date().toISOString().substr(0, 10),
          new Date().toISOString().substr(0, 10)
          ],
        depensesInfos:{
          date_dep: '',
          somme: '',
          motif: '',
          remis: '',
          recu: '',
          matricule: '',
          type_dep: '',
        },
        isMobile: false,
        popupActivo2:false,
        popupActivo3:false,
        activePrompt9:false,
        select1:3,
        options1:[
          {text:'IT',value:0},
          {text:'Blade Runner',value:2},
          {text:'Thor Ragnarok',value:3},
        ],
        formDate: {
          dateBen: ''
        },
        checkTrue: true,
        donneesClient: null,
        donneesCliProd: null,
        checkFalse: false,
        totalVoyages:0,
        donnees: null,
        donneesCompta : null,
        totalPrix : 0,
        totalSalaire : 0,
        totalSalaireok : 0,
        totalVehicule : 0,
        totalFrais : 0,
        totalCarburant : 0,
        totalDivers : 0,
        totalVehicules : 0,
        totalReparation: 0,
        totalTransit: 0,
        totalStation: 0,
        totalDon: 0,
        totalInteret: 0,
        totalNotaire: 0,
        totalAssurance: 0,
        totalLocation: 0,
        totalBureautique: 0,
        don_Produits: null,
        don_Clients: [{"client":"0","produit":"0","total_enregistrements":"0","total_poids":"0"}],
        don_Clients2: [{"client":"0","produit":"0","total_enregistrements":"0","total_poids":"0"}],
        don_Camion: null,
        donneesStats: {
          dn_carb: [{"total":"0","litre":"0","prix":"0"}],
          dn_dep: [{"total":"0","prix":"0"}],
          dn_voyages: [{"chauffeur":"","client":"","dossier":"0","id_ben":"0","lieu_chargement":"0","lieu_dechargement":"","n_bl":"","n_matricule":"","observation":"0","par":"0","poids":"0","prix":"0","produit":"0"}],
          dn_clients: [{"client":"0","produit":"0","total_enregistrements":"0","total_poids":"0"}],
          nb_carb: 0,
          nb_dep: 0,
          nb_voyages: 0,
          tableau: [{"camion":"0","chauffeur":"0","date_carb":"0","details":"0","id_camion":"0","id_carburant":"0","imatriculation":"0","litre":"0","marque":"0","nombre_de_bennes":"0","prix":"0"}],
        },
        donneesStatsMois: null,
        donneesStatsSemaine: null,
        elementChoisi: null,
        donneesZones: null,
        donneesCamion: null,
        donneesTypeDep: null,
        ClientChoisi: {
          client: '',
          produit: '',
          total_enregistrements: '',
          total_poids: ''
        },
        donneesChauf: null,
        deadline3: Date.now() + (new Date().setHours(17, 59, 59) - Date.now()),
        like: true,
          value1: '',
          value2: '',
          title: 'Growth this year',
        activeName: 'first',
        chartData: {
          labels: [ 'Janvier', 'Fevrier', 'Mars', 'Avril', 'Mais', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre' ],
          datasets: [ { data: [40, 20, 12, 33, 10, 3, 45, 41, 26, 11, 19, 26] } ]
        },
        chartOptions: {
          responsive: true
        },
        clientChoix: {

        },
        form: {
          date_recept: '',
          n_doc: '',
          n_conteneur: '',
          type_c: '',
          nbre_tcs: 1,
          n_declaration: '',
          bl: '',
          statut: '',
          armateur: '',
          client: '',
          camion: '',
          chauffeur: '',
          zone_livraison: '',
          zone_facturation: '',
          badt: '',
          do: '',
          sortie_port: '',
          sejour: '',
          date_requete: '',
          date_recup: '',
          entree_parc: '',
          transitaire: '',
          observation: ''
          },
          options: [{
            value: 'Option1',
            label: 'Option1'
          }, {
            value: 'Option2',
            label: 'Option2'
          }, {
            value: 'Option3',
            label: 'Option3'
          }, {
            value: 'Option4',
            label: 'Option4'
          }],
          type_cont: [{
            value: "20 'OPEN",
            label: "20 'OPEN"
          }, {
            value: "40 'OPEN",
            label: "40 'OPEN"
          }, {
            value: "20 'FLAT",
            label: "20 'FLAT"
          },{
            value: "40 'FLAT",
            label: "40 'FLAT"
          }, {
            value: "20 'DRY",
            label: "20 'DRY"
          }, {
            value: "40 'DRY",
            label: "40 'DRY"
          }, {
            value: "20 'FRIGO",
            label: "20 'FRIGO"
          }, {
            value: "40 'FRIGO",
            label: "40 'FRIGO"
          }],
          armateur: [{
            value: "Evergreen",
            label: "Evergreen"
          }, {
            value: "Maersk",
            label: "Maersk"
          }, {
            value: "Safmarine",
            label: "Safmarine"
          },{
            value: "Cosco",
            label: "Cosco"
          }, {
            value: "One",
            label: "One"
          }, {
            value: "PIL",
            label: "PIL"
          }, {
            value: "Oma",
            label: "Oma"
          }, {
            value: "Medlog",
            label: "Medlog"
          }],
        tableData: [{
            date: '2016-05-03',
            name: 'TRAORE Oumar',
            address: 'No. 189, Grove St, Los Angeles',
            st: 'En cours'
          }, {
            date: '2016-05-02',
            name: 'SAKONE Oumar',
            address: 'No. 189, Grove St, Los Angeles',
            st: 'Validé'
          }, {
            date: '2016-05-04',
            name: 'SAKONE Oumar',
            address: 'No. 189, Grove St, Los Angeles',
            st: 'En cours'
          }, {
            date: '2016-05-01',
            name: 'SAKONE Oumar',
            address: 'No. 189, Grove St, Los Angeles',
            st: 'Annulé'
          }, {
            date: '2016-05-08',
            name: 'SAKONE Oumar',
            address: 'No. 189, Grove St, Los Angeles',
            st: 'Validé'
          }, {
            date: '2016-05-06',
            name: 'SAKONE Oumar',
            address: 'No. 189, Grove St, Los Angeles',
            st: 'En cours'
          }, {
            date: '2016-05-07',
            name: 'SAKONE Oumar',
            address: 'No. 189, Grove St, Los Angeles',
            st: 'En cours'
          }],
      }
    },
    methods: {

      modifBon(list) {
        console.log(list)
        this.clientChoix = {
          libelle: list.libelle,
          id: list.id_client,
        },
          this.popupActivo22 = true;
          
        },

        supBon(id) {
        this.deletebon = id;
        this.$vs.dialog({
        color:'danger',
        title: `Suppression`,
        text: 'Êtes vous sûre de vouloir supprimer ?',
        accept:this.onDelete
          })
          
        },

        onSubmit() {
              axios.post('https://socogetra.net/appi_sococit/albakoos_clients.php', this.depensesInfos)
              .then((response) => {
                  console.log(response);
                  this.$vs.loading.close();
                  this.popupActivo2 = false;
                  this.listeDepenses();
                  this.$notify.success({
                  title: 'Clients !',
                  message: 'Enregistré avec Succès',
                  showClose: false
                  });
              })
              .catch(function (error) {
                  console.log(error);
                  this.$vs.loading.close()
              });
          },

          onSubmitt() {
              axios.post('https://socogetra.net/appi_sococit/types_depenses.php', this.depensesInfos)
              .then((response) => {
                  console.log(response);
                  this.$vs.loading.close();
                  this.popupActivo2 = false;
                  this.depensesInfos = {
                    date_dep: '',
                    somme: '',
                    motif: '',
                    remis: '',
                    recu: '',
                    matricule: '',
                    type_dep: '',
                  };
                  this.listeDepenses();
                  this.$notify.success({
                  title: 'Dépense !',
                  message: 'Enregistré avec Succès',
                  showClose: false
                  });
              })
              .catch(function (error) {
                  console.log(error);
                  this.$vs.loading.close()
              });
          },

          generateReport () {
              this.activePrompt9 = true;
              this.$refs.html2Pdf.generatePdf()
          },

        onUpdate() {
              axios.put('https://socogetra.net/appi_sococit/albakoos_clients.php', this.clientChoix)
              .then((response) => {
                  console.log(response);
                  this.$vs.loading.close();
                  this.popupActivo2 = false;
                  this.popupActivo22 = false;
                  this.listeDepenses();
                  this.$notify.success({
                  title: 'Dépense !',
                  message: 'Enregistré avec Succès',
                  showClose: false
                  });
              })
              .catch(function (error) {
                  console.log(error);
                  this.$vs.loading.close()
              });
          },

          onDelete() {
              axios.delete('https://socogetra.net/appi_sococit/albakoos_depenses.php?id='+this.deletebon)
              .then((response) => {
                  console.log(response);
                  this.$vs.loading.close();
                  this.popupActivo2 = false;
                  this.depensesInfos = {
                    date_dep: '',
                    somme: '',
                    motif: '',
                    remis: '',
                    recu: '',
                    matricule: '',
                    type_dep: '',
                  };
                  this.listeDepenses();
                  this.$notify.success({
                  title: 'Dépense !',
                  message: 'Enregistré avec Succès',
                  showClose: false
                  });
              })
              .catch(function (error) {
                  console.log(error);
                  this.$vs.loading.close()
              });
          },

          supProd(id) {
              axios.delete('https://socogetra.net/appi_sococit/albakoos_produits_clients.php?id='+id)
              .then((response) => {
                  console.log(response);
                  this.$vs.loading.close();
                  this.popupActivo2 = false;
                  window.location.reload();
                  this.$notify.success({
                  title: 'Dépense !',
                  message: 'Enregistré avec Succès',
                  showClose: false
                  });
              })
              .catch(function (error) {
                  console.log(error);
                  this.$vs.loading.close()
              });
          },

          async listeDepenses() {
            this.$vs.loading({
              type: 'point',
            });

            try {
              const response = await fetch('https://socogetra.net/appi_sococit/albakoos_depenses.php?timestamp=' + new Date().getTime());
              const data = await response.json();
              this.updateDataFromResponse(data);
              this.$vs.loading.close();
            } catch (error) {
              console.log(error);
              this.$vs.loading.close();
            }
          },

          async searchDate() {
            this.$vs.loading({
              type: 'point',
            });

            const year = this.dateSearch[0].getFullYear();
            const month = String(this.dateSearch[0].getMonth() + 1).padStart(2, '0');
            const day = String(this.dateSearch[0].getDate()).padStart(2, '0');

            const year2 = this.dateSearch[1].getFullYear();
            const month2 = String(this.dateSearch[1].getMonth() + 1).padStart(2, '0');
            const day2 = String(this.dateSearch[1].getDate()).padStart(2, '0');

            const dte_db = `${year}-${month}-${day}`;
            const dte_fn = `${year2}-${month2}-${day2}`;

            try {
              const response = await fetch(`https://socogetra.net/appi_sococit/albakoos_depense_periode.php?date_db=${dte_db}&date_fn=${dte_fn}&timestamp=${new Date().getTime()}`);
              const data = await response.json();
              this.renit(data);
              this.$vs.loading.close();
            } catch (error) {
              console.log(error);
              this.$vs.loading.close();
            }
          },

          updateDataFromResponse(data) {
            this.donneesCompta = data.data;
            this.totalPrix = data.total;
            this.totalSalaire = data.salaire;
            this.totalSalaireok = data.salaire[0].total_depense;
            this.totalVehicule = data.vehicule;
            this.totalFrais = data.frais;
            this.totalCarburant = data.carburant;
            this.totalAssurance = data.assurance;
            this.totalLocation = data.location;
            this.totalBureautique = data.bureautique;
            this.totalReparation = data.reparation;
            this.totalTransit = data.transit;
            this.totalStation = data.stationnement;
            this.totalNotaire = data.notaire;
            this.totalDon = data.don;
            this.totalInteret = data.interet;
            this.totalDivers = data.divers;
            this.totalVehicules = data.vehicules;
          },

            renit(data) {
            this.donneesCompta = data.data;
            this.totalPrix = '';
            this.totalSalaire = '';
            this.totalSalaireok = '';
            this.totalVehicule = '';
            this.totalFrais = '';
            this.totalCarburant = '';
            this.totalAssurance = '';
            this.totalLocation = '';
            this.totalBureautique = '';
            this.totalReparation = '';
            this.totalTransit = '';
            this.totalStation = '';
            this.totalNotaire = '';
            this.totalDon = '';
            this.totalInteret = '';
            this.totalDivers = '';
            this.totalVehicules = '';
          },

      choixPeriode(a) {
        this.periode = a;
        console.log(this.periode);
      },

      listeZones2 () {
            
            axios.get('https://socogetra.net/appi_sococit/albakoos_produits_clients.php?timestamp=' + new Date().getTime())
            .then((response) => {
              this.donneesClient = response.data.data;
              this.$vs.loading.close()
              this.filtreUser();
            }, (error) => {
              console.log(error);
              this.$vs.loading.close()
            });
        },

        Stats() {
              this.$vs.loading({
                type: 'point',
              });
              axios.get('https://socogetra.net/appi_sococit/albakoos_stats.php?timestamp=' + new Date().getTime())
              .then((response) => {
                this.totalVoyages = 0;
                this.donneesStats = response.data;
                this.don_Clients = response.data.dn_clients;
                this.don_Clients.forEach((item) => {
                  this.totalVoyages = this.totalVoyages + parseInt(item.total_enregistrements);
                })
                console.log(this.don_Clients);
                this.$vs.loading.close();
              }, (error) => {
                console.log(error);
                this.$vs.loading.close();
              });
            },

           
        StatsMonth () {
            this.$vs.loading({
            type:'point',
            })
            axios.get('https://socogetra.net/appi_sococit/albakoos_stats_month.php?timestamp=' + new Date().getTime())
            .then((response) => {
              this.donneesStatsMois = response.data;
              this.$vs.loading.close()
            }, (error) => {
              console.log(error);
              this.$vs.loading.close()
            });
        },

        StatsWeek () {
            this.$vs.loading({
            type:'point',
            })
            axios.get('https://socogetra.net/appi_sococit/Albakoos_stats_week.php?timestamp=' + new Date().getTime())
            .then((response) => {
              this.donneesStatsSemaine = response.data;
              this.$vs.loading.close()
            }, (error) => {
              console.log(error);
              this.$vs.loading.close()
            });
        },
        
  
        listeDossier () {
            this.$vs.loading({
            type:'point',
            })
            axios.get('https://socogetra.net/appi_sococit/albakoos_dossier_sbtl.php?timestamp=' + new Date().getTime())
            .then((response) => {
              this.donnees = response.data.data;
              this.$vs.loading.close()
            }, (error) => {
              console.log(error);
              this.$vs.loading.close()
            });
        },

        formatMoney(value) {
          const numberValue = parseFloat(value.replace(/ /g, '').replace(/,/g, '.'));
          if (isNaN(numberValue)) {
            return '';
          }
          return numberValue.toLocaleString('fr-FR', {
            style: 'currency',
            currency: 'XOF'
          });
        },
  
        listeZones () {
              this.$vs.loading({
              type:'point',
              })
              axios.get('https://socogetra.net/appi_sococit/albakoos_zones.php?timestamp=' + new Date().getTime())
              .then((response) => {
                this.donneesZones = response.data.data;
                this.$vs.loading.close()
              }, (error) => {
                console.log(error);
                this.$vs.loading.close()
              });
          },

          typeDep () {
              this.$vs.loading({
              type:'point',
              })
              axios.get('https://socogetra.net/appi_sococit/types_depenses.php?timestamp=' + new Date().getTime())
              .then((response) => {
                this.donneesTypeDep = response.data.data;
                this.$vs.loading.close()
              }, (error) => {
                console.log(error);
                this.$vs.loading.close()
              });
          },

          showClient(client) {
            this.ClientChoisi = client;
            console.log(this.ClientChoisi);
            this.popupActivo2 = true
          },
  
          exportToPDF() {
            html2pdf(document.getElementById("html-to-pdf"), {
              margin: 1,
              filename: "i-was-html.pdf",
            });
          },
  
          listeChauffeurs () {
              this.$vs.loading({
              type:'point',
              })
              axios.get('https://socogetra.net/appi_sococit/albakoos_chauffeurs.php?timestamp=' + new Date().getTime())
              .then((response) => {
                this.donneesChauf = response.data.data;
                this.$vs.loading.close()
              }, (error) => {
                console.log(error);
                this.$vs.loading.close()
              });
          },
  
          Borderau(element) {
            this.$router.push({ name: 'camion_mobile', params: { element: element } });
          },
  
          generatePDF () {
            var doc = new jsPDF();
            
            // Récupérer l'élément HTML que vous voulez convertir en PDF
            const element = document.getElementById('html-to-pdf')
  
            // Convertir l'élément HTML en Canvas
            html2canvas(element).then(canvas => {
              // Ajouter le Canvas au document PDF avec une résolution de 300 DPI
              const imgData = canvas.toDataURL('image/png', 2.7);
              doc.addImage(imgData, 'PNG', 10, 10);
              
              // Sauvegarder le document PDF
              doc.save('example.pdf')
            })
          },
  
         // generatePDF () {
          //  var doc = new jsPDF();
  
            // Récupérer l'élément HTML que vous voulez convertir en PDF
          //  const element = document.getElementById('html-to-pdf')
  
            // Convertir l'élément HTML en Canvas
          //  html2canvas(element).then(canvas => {
              // Ajouter le Canvas au document PDF
           //   const imgData = canvas.toDataURL('image/png')
            //  doc.addImage(imgData, 'PNG', 10, 10)
  
              // Sauvegarder le document PDF
           //   doc.save('example.pdf')
          //  })
         // },
  
          listeCamion () {
              this.$vs.loading({
              type:'point',
              })
              axios.get('https://socogetra.net/appi_sococit/albakoos_camion.php?timestamp=' + new Date().getTime())
              .then((response) => {
                this.donneesCamion = response.data.data;
                this.$vs.loading.close()
              }, (error) => {
                console.log(error);
                this.$vs.loading.close()
              });
          },
  
          handleSelectChange(choix) {
            console.log(choix);
            const oumarItem = this.donneesZones.find(item => item.zone_livraison === choix);
            this.form.zone_facturation = oumarItem ? oumarItem.zone_facturation : null;
          },
  
        clickFn() {
          this.$refs.statistic.suspend(this.stop);
          this.stop = !this.stop;
        },
        handleClick(tab, event) {
          console.log(tab, event);
        },
        hilarity() {
          this.$notify({
            title: 'TRAVAIL DU JOUR TERMINE',
            message: "C'est bon pour aujourd'hui, vous pouvez entrez à la maison",
            duration: 0,
          });
        },

        getData() {
          const token = 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjA1REVBNjlBOUIxODE4RjI3RkIxNUQ2QzQ2QkI0NEU0IiwidHlwIjoiYXQrand0In0.eyJpc3MiOiJodHRwczovL2FjY291bnQubmF2aXMuY29tIiwibmJmIjoxNjg0MTAzMjY3LCJpYXQiOjE2ODQxMDMyNjcsImV4cCI6MTY4NDExMDQ2NywiYXVkIjpbImNvbXBhbnljbG91ZC1wdWJsaWNhcGkiLCJodHRwczovL2FjY291bnQubmF2aXMuY29tL3Jlc291cmNlcyJdLCJzY29wZSI6WyJjb21wYW55Y2xvdWQtcHVibGljYXBpIiwib2ZmbGluZV9hY2Nlc3MiXSwiYW1yIjpbInB3ZCJdLCJjbGllbnRfaWQiOiJTbWFydEFjY2Vzcy1BcHAiLCJzdWIiOiI4NDVmNTIzYS0xMTBjLTQ4NjQtODVkNC1hYzE3NzlkN2YxZDAiLCJhdXRoX3RpbWUiOjE2ODQxMDMyNjYsImlkcCI6ImxvY2FsIiwiZW1haWwiOiJzYWtvbmVAc29jb2dldHJhLm5ldCIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2VtYWlsYWRkcmVzcyI6InNha29uZUBzb2NvZ2V0cmEubmV0Iiwiam9pbmRhdGUiOiIwNS8wMy8yMDIzIDEwOjI1IiwicGljdHVyZSI6Imh0dHBzOi8vaW1hZ2VzLmFjY291bnQubmF2aXMuY29tL2VmZmVjNmUyLTIzNWQtNDNjMy05YmVmLTNlNTBkZDM0MmEwMS8xMDAucG5nIiwiZ2l2ZW5fbmFtZSI6IlNBS09ORSIsImZhbWlseV9uYW1lIjoiT3VtYXIiLCJzaWQiOiJFMkE2QzU4RkVDQzM0MDlFNjk4NDUzMURGNjc4RUMxQyJ9.rPA25RdckhXhl5sWBjVhI-C-8rIu3dX-w9zP2rk1Kwfpw8ED9LYV3J8d2sQknShx8vGTaZwSCiQLC55KNM1mi1b603uH8QEqz-Pb1Ql-wNWv_5NHIDdEG7d45G2KEX_CNjKBnNHS6agoatJbbjHQymdxmaudwQRqDJo9-mDXsG_ZUwD-vjYFQKvFqBzVJricJZiwrDAZXXIp9Yc2AvOVFsQs3DHXHk8GmY98q3-qt_Dfbr1UiJM-iejYzCO-dAa-YX2D78XK0UG9ejMcwKB1jBXpt0TgLMSHKIKcQSGVX5qPj_IHBB_dFgf3nNmrCz7mgoe4p1BCWHuItfk2iF7ccg'
          const url = 'https://api-smartaccess.cotedivoireterminal.com/smartaccess/api/gateway/vessel/visits'
          axios.get(url, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          })
          .then(response => {
            console.log(response.data)
          })
          .catch(error => {
            console.log(error)
          })
        }
      },
  
      mounted() {
        fetch('https://socogetra.net/appi_sococit/albakoos_clients.php?timestamp=' + new Date().getTime())
        .then(response => response.json())
        .then(data => {
          this.don_Clients = data.data;
          console.log(data)
        })
        .catch(error => {
          console.error(error);
        });
        fetch('https://socogetra.net/appi_sococit/albakoos_produits_clients.php?timestamp=' + new Date().getTime())
        .then(response => response.json())
        .then(data => {
          this.don_Produits = data.datas;
          console.log(data)
        })
        .catch(error => {
          console.error(error);
        });
        this.getData();
        this.listeDepenses();
        this.typeDep();
      },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .el-input__inner::placeholder {
      color: #201f1f;
  }
  
  </style>
  