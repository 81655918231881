<template>
    <div class="hello">
        <div class="head-nav" v-if="isMobile">
            <div style="width: 380px; ">
              <div style="background-color: #efb1b173; width: 250px;">
                <span><i class="el-icon-s-grid iconMenu" style="color: whitesmoke; padding: 14px 14px; font-size: 20px;"></i></span>
                <span style="color: whitesmoke; font-weight: bold; padding: 15px;">SOGECIT COMPTABILITE</span>
              </div>
            </div>
            
           
            
          </div>
          <div class="head-nav" v-else>
            <div style="width: 380px; ">
              <div style="background-color: #efb1b173; width: 280px;">
                
                <span><i class="el-icon-s-grid iconMenu" style="color: whitesmoke; padding: 14px 14px; font-size: 20px;"></i></span>
                <span style="color: whitesmoke; font-weight: bold; padding: 15px;">SOGECIT COMPTABILITE</span>
                
              </div>
              
            </div>
            
            <div style="width: 1150px; margin-left: auto; order: 2; background-color: #efb1b173;">
              <vs-button to="/" class="button" color="#DB626C" type="gradient" style="width: 170px; margin: 3px; background-color: rgb(83, 104, 27);" icon="paid">Clients & Produits</vs-button>
              <vs-button to="/frais_finance" class="button" color="#DB626C" type="gradient" style="width: 150px; margin: 3px; background-color: rgb(83, 104, 27);" icon="account_balance">Frais Finance</vs-button>
              <vs-button to="/frais_famille" class="button" color="#DB626C" type="gradient" style="width: 150px; margin: 3px; background-color: rgb(83, 104, 27);" icon="diversity_1">Dépenses Familliale</vs-button>
              <vs-button to="/gestion_carburant" class="button" color="#DB626C" type="gradient" style="width: 200px; margin: 3px; background-color: rgb(83, 104, 27);" icon="local_gas_station">Gestion Carburants</vs-button>
              <vs-button to="/etat_carburant" class="button" color="#DB626C" type="gradient" style="width: 200px; margin: 3px; background-color: rgb(83, 104, 27);" icon="local_shipping">Bilan Camion</vs-button>
              <div style="float: right;">
                <span class="iconUser" style="padding: 0px 14px; float: right; font-weight: bold; color: whitesmoke; ">
                  <span style="border: 1px solid whitesmoke; border-radius: 50%; padding: 7px;">{{users.name[0] | uppercase}}{{users.prenom[0] | uppercase}}</span>
                </span>
                <span style="color: whitesmoke; font-weight: bold; padding: 12px; font-size: 12px;">{{users.name}} {{users.prenom}}</span>
              </div>
            </div>
            
          </div>
       
    </div>
  </template>
  
  <script>
  export default {
    name: 'HelloWorld',
    computed: {
    users() {
      return this.$store.state.users;
        }
      },
    data() {
      return {
        isMobile: false,
        input4: '',
        deadline3: Date.now() + (new Date().setHours(17, 59, 59) - Date.now()),
        like: true,
          value1: 4154.564,
          value2: 2222,
          title: 'Growth this year',
        activeName: 'first',
        chartData: {
          labels: [ 'Janvier', 'Fevrier', 'Mars', 'Avril', 'Mais', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre' ],
          datasets: [ { data: [40, 20, 12, 33, 10, 3, 45, 41, 26, 11, 19, 26] } ]
        },
        chartOptions: {
          responsive: true
        }
      }
    },
    methods: {
        uppercase: function (value) {
          if (!value) return ''
          return value.toUpperCase()
        },
        clickFn() {
          this.$refs.statistic.suspend(this.stop);
          this.stop = !this.stop;
        },
        handleClick(tab, event) {
          console.log(tab, event);
        },
        hilarity() {
          this.$notify({
            title: 'TRAVAIL DU JOUR TERMINE',
            message: "C'est bon pour aujourd'hui, vous pouvez entrez à la maison",
            duration: 0,
          });
        },
      }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  </style>
  